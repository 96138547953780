import { useState, useEffect } from 'react';

import { trans, useSoyuzLocales, useSoyuzFlag } from '@spotahome/soyuz/client';
import { LoginSignUpModal } from '@spotahome/ui-library';
import { Mediator } from '@spotahome/soyuz-mediator';

import {
  COMMON_LOGIN_MODAL,
  EXTERNAL_REDIRECTION_LOGIN_MODAL
} from '../../channels';

import {
  traditionalSignup,
  traditionalLogin,
  traditionalForgotPassword
} from '../../api/traditional-login-client';

const handleSuccess = redirectUrl => {
  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    document.location.reload();
  }
};

// eslint-disable-next-line react/prop-types
const LoginModal = () => {
  const { current: locale } = useSoyuzLocales();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [error, setError] = useState(null);
  const hideTraditionalAuth = useSoyuzFlag('HideTraditionalAuth').isOn();
  const hideFacebookOauth = useSoyuzFlag('HideFacebookOauth').isOn();
  const hideGoogleOauth = useSoyuzFlag('HideGoogleOauth').isOn();

  const handleExternalRedirectionOpen = ({ url, ...restPayload }) => {
    setRedirectUrl(url);
    Mediator.publish(COMMON_LOGIN_MODAL, {
      ...restPayload,
      socialOptions: {
        redirectUrl: url
      }
    });
  };

  useEffect(() => {
    Mediator.subscribe(
      EXTERNAL_REDIRECTION_LOGIN_MODAL,
      handleExternalRedirectionOpen
    );

    return () => {
      Mediator.unsubscribe(EXTERNAL_REDIRECTION_LOGIN_MODAL);
    };
  }, []);

  const handleTraditionalLogin = async ({ email, password }) => {
    try {
      setError(null);
      await traditionalLogin({ email, password });
      handleSuccess(redirectUrl);
    } catch (e) {
      setError(trans('login.error'));
      console.error(e);
    }
  };

  const handleTraditionalSignup = async ({
    name,
    email,
    password,
    marketingOptIn
  }) => {
    try {
      setError(null);
      await traditionalSignup({
        name,
        email,
        password,
        marketingOptIn,
        locale
      });
      handleSuccess(redirectUrl);
    } catch (e) {
      setError(trans('signup.error'));
      console.error(e);
    }
  };

  const handleForgottenPassword = async ({ email }) => {
    try {
      setError(null);
      await traditionalForgotPassword({ email });
    } catch (err) {
      setError(trans('forgotten-password.error'));
      console.error(err);
    }
  };

  return (
    <LoginSignUpModal
      locale={locale}
      onTraditionalLogin={handleTraditionalLogin}
      onTraditionalSignup={handleTraditionalSignup}
      onForgottenPassword={handleForgottenPassword}
      error={error}
      enableTemporalOfferToast
      hideTraditionalAuth={hideTraditionalAuth}
      hideFacebookOauth={hideFacebookOauth}
      hideGoogleOauth={hideGoogleOauth}
    />
  );
};

export default LoginModal;
